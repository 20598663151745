.money-section {
  background: url("../../Images/moneyBg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 150px;
  background-position: center;
}

.money-section.moneyBg4 {
  background: url("../../Images/moneyBg4.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 150px;
  background-position: center;
}

.money-section-text h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  margin-top: 10px;
}

.money-section-text h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.7;
  margin-top: 10px;
  padding-bottom: 30px;
}

.money-section-text {
  max-width: 500px;
}

.money-section-text a {
  background: #8649d1;
  border-radius: 100px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 14px 90px;
  text-decoration: none;
  max-width: 350px;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .money-section {
    background: url("../../Images/moneyBgMobile.webp");
    background-repeat: no-repeat;
    text-align: center;
    padding-bottom: 0;
    background-size: cover;
    margin-top: 115px;
  }

  .money-section.moneyBg4 {
    background: url("../../Images/moneyBg4Mobile.webp");
    background-repeat: no-repeat;
    text-align: center;
    padding-bottom: 0;
    background-size: cover;
    margin-top: 115px;
  }

  .money-section h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
  }
  .money-section h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
  }

  .money-section-text {
    margin: 0 auto;
  }

  .money-section-text a {
    margin: 0 auto;
  }
}
