.return-section-text h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    color: #ffffff;
    margin-top: 30px;
    padding-bottom: 30px;
    width: 600px;
}

.return-section-text a {
    padding: 14px 50px;
    border-radius: 100px;
    background: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #00063d;
    text-decoration: none;
    max-width: 350px;
    text-align: center;
}

.return-section .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.return-section {
    background: linear-gradient(180deg, #00063d 0%, #6534a0 100%), #00063d;
    height: 700px;
}

.return-section-image {
    background: url("../../Images/retirnBg.webp");
    position: relative;
    width: 100%;
    height: 445px;
    background-repeat: no-repeat;
    background-size: cover;
}

#firstText {
    position: absolute;
    top: 25px;
    left: 29px;
    max-width: 250px;
}

#secondText {
    position: absolute;
    top: 220px;
    right: 3px;
    max-width: 250px;
}

#thirdText {
    position: absolute;
    top: 286px;
    left: 5px;
    max-width: 250px;
}

.return-section-image h4 {
    color: #fff;
    text-align: center;
    text-shadow: 5px 5px 15px rgba(86, 0, 192, 0.62);
    font-size: 28.119px;
    font-weight: 700;
    line-height: 100%;
}

@media screen and (max-width: 800px) {
    .return-section .container {
        flex-direction: column;
    }
    .return-section-image img {
        width: 100%;
        margin-top: 25px;
    }
    .return-section-text h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.02em;
        width: 100%;
    }
    .return-section-text a {
        margin: 0 auto;
    }
    .return-section .logo-mobile {
        padding-top: 100px;
    }
    .return-section-image {
        width: 100%;
        margin-top: 15px;
    }
    .return-section-image h4 {
        font-size: 20px;
    }
    #firstText {
        position: absolute;
        top: 80px;
        left: 29px;
        max-width: 200px;
    }
    #secondText {
        position: absolute;
        top: 200px;
        right: 3px;
        max-width: 200px;
    }
    #thirdText {
        position: absolute;
        top: 286px;
        left: 5px;
        max-width: 200px;
    }
}