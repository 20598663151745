.succeed-section h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    color: #ffffff;
}

.succeed-section h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #ffffff;
    opacity: 0.7;
    margin-top: 10px;
}

.succeed-section-card .card h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
}

.succeed-section-card .card p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 30px;
}

.succeed-section-card .card a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    background: transparent;
    border: 1px solid #ffffff;
    padding: 14px 50px;
    text-decoration: none;
    border-radius: 100px;
    margin: 0 auto;
}

.succeed-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 65px;
    padding-top: 200px;
}

.succeed-section-card .card {
    background: linear-gradient(180deg, #1fb9f2 0%, #00aff0 50.31%, #008ccf 100%);
    border-radius: 12px;
    width: 470px;
}

.succeed-section-card.green .card {
    background: linear-gradient(193.2deg, #4cb962 12.58%, #208b43 115.54%);
}

.succeed-section-card .card img {
    width: 100%;
    height: 100%;
    border-radius: 12px 12px 0px 0px;
}

.succeed-section-card .card .card-text {
    padding: 30px;
}

.block-white {
    background: #ffffff;
    border-radius: 0px 175.606px 0px 0px;
    width: 50%;
    height: 100px;
}

@media screen and (max-width: 800px) {
    .succeed-section {
        flex-direction: column;
    }
    .succeed-section-card .card {
        width: 100%;
    }
    .succeed-section {
        padding-top: 70px;
    }
    .succeed-section-card .card .card-text {
        padding: 15px;
    }
    .succeed-section-card {
        padding-bottom: 40px;
    }
}

@media (max-width: 490px) {
    .succeed-section h1 {
        text-align: center;
        font-size: 38px;
    }
    .succeed-section h4 {
        text-align: center;
        font-size: 15px;
    }
    .percentage-section {
        padding-bottom: 25px;
    }
    .money-section {
        padding-top: 120px;
        padding-bottom: 60px;
    }
    .marks-section {
        padding-top: 0px;
    }
    .seek-section {
        background: #f2ecfa;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}