.percentage-section .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.percentage-section-text h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 140%;
  color: #ffffff;
  opacity: 0.7;
}

.percentage-section {
  padding-bottom: 75px;
}

.percentage-section {
  background: linear-gradient(180deg, #6534a0 0%, #00063d 47.92%);
  max-height: 700px;
  padding-top: 165px;
  padding-bottom: 165px;
}

.percentage-section-img {
  max-width: 545px;
}

.percentage-section-img img {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .percentage-section .container {
    flex-direction: column-reverse;
    text-align: center;
  }
  .percentage-section-text h4 {
    opacity: unset;
  }
}
